
import {  computed, reactive, toRefs, watch } from 'vue'
import { PlusCircleOutlined } from '@ant-design/icons-vue'
import { isValid, roleList, deleteRole, editUser, userDetail, userList, addUser, initPassWord} from '../../utils/api'
import { showMessage, confirmDialog, geneUniId, decodePassword, geneIndex } from '../../utils/util'
import { ChooseRoleVo } from '../../utils/model'
import { useStore } from 'vuex'
import { message } from 'ant-design-vue'
import md5 from 'js-md5'


export default {
    name: '',
    components: { PlusCircleOutlined },
    setup() {
        console.log(useStore())
         const roles: ChooseRoleVo[] = []
         const state= reactive({
             buttons: [
                 {text: '编辑', eventName: 'edit'},
                 {text: '重置密码', eventName:'resetPassword'}
             ],
             columns: [
                 {title: '序号',  dataIndex: 'index', key: 'index'},
                 {title: '用户编号',  dataIndex: 'userId', key: 'roleName'},
                 {title: '真实姓名',  dataIndex: 'name', key: 'name'},
                //  {title: '用户昵称',  dataIndex: 'userName', key: 'userName'},
                //  {title: '电子邮箱',  dataIndex: 'email', key: 'email'},
                 {title: '手机号码',  dataIndex: 'phone', key: 'phone'},
                 {title: '状态',  dataIndex: 'statusText', key: 'statusText'},
                //  {title: '权限范围',  dataIndex: 'roleDesc', key: 'roldDesc'},
                 {title: '操作',  dataIndex: 'manage', key: 'name',  slots: {customRender: 'actions'}},
             ],
             title: '添加新用户',
             confirmLoading: false,
             showDialog: false,
             formItems: [
                    {label: '真实姓名', value: '', type: 'input', name: 'name'},
                    // {label: '用户昵称', value: '', type: 'input', name: 'loginName'},
                    {label: '登陆密码', value: '', type: 'input', name: 'passWord', hidden: true},
                    {label: '手机号码', value: '', type: 'input', name: 'phone'},
                    {label: '电子邮箱', value: '', type: 'input', name: 'email'},
                    {label: '状态', value: '', type: 'selector', name: 'status', key: 'value', 
                        options: [
                            {labelName: '初始', value: 0}, 
                            {labelName: '冻结', value: 3},
                            {labelName: '停用', value: 4},
                            {labelName: '正常', value: 10},
                        ]}
            ],
            passwordForm: [
                 {label: '新密码', value: '', type: 'input', name: 'password'}
            ],
            rules: {
                trueName: [
                     { required: true, message: '内容不能为空', trigger: 'blur'}
                ],
                loginName: [
                     { required: true, message: '内容不能为空', trigger: 'blur'}
                ],
                passWord: [
                     { required: true, message: '内容不能为空', trigger: 'blur'}
                ],
            },
            dialogType: 'edit',
            forms: {
                trueName: '',
                loginName: '',
                passWord: '',
                phone: '',
                email: '',
                status: '',
            },
            checkedKeys: [], // 用户选择数据
            currentPage: 1, //当前页面
            pageSize: 10, // 每页行数
            tableList: [],
            editRoleId: '',
            myRoles: roles,
            total: 0,
            loading: false,
            userInfo: {userId: 0},
        })
        const trueDialogForm = computed(() => {
            return state.dialogType == 'resetPassword' ? state.passwordForm : state.formItems
        })
        // 赋值
        function assignValues(type = true) {
            if(type) {
                state.forms.trueName = state.formItems[0].value
                // state.forms.loginName = state.formItems[1].value
                state.forms.passWord = md5(state.formItems[1].value)
                state.forms.phone = state.formItems[2].value 
                state.forms.email = state.formItems[3].value
                state.forms.status = state.formItems[4].value
            }
        }
        watch(state, () => {
            assignValues()
        })
        // 获取角色列表 
        async function getRoles() {
            try {
                const params = {
                    pageNum: state.currentPage,
                    pageSize: 1000
                }
               const result = await roleList(params)
               if (isValid(result)) {
                //    state.myRoles = result.data.records
                   result.data.records.forEach((ele: any) => ele.isSelect = false)
                   state.myRoles = result.data.records
               }
            } catch(e) {
                console.error(e)
            }
        }
        // 重置密码
        async function resetPassword() {
            try {
                const params = {
                    userId: state.userInfo.userId,
                    password: md5(state.passwordForm[0].value)
                }
                const result = await initPassWord(params)
                if (isValid(result)) {
                    message.success('密码重置成功')
                    state.showDialog = false
                }
            } catch(e) {
                console.error(e)
            }
        }
        // 获取角色列表
        getRoles()
        // 获取用户列表
        async function loadData() {
            try {
                state.loading = true
                const query = {
                    pageNum: state.currentPage,
                    pageSize: state.pageSize
                }
                const result = await userList(query)
                if(isValid(result)) {
                    state.tableList = geneIndex(result.data.records, state.pageSize, state.currentPage)
                    state.tableList.forEach((ele: any) => {
                        ele.statusText = ele.status == 0 ? '初始'
                                        : ele.status == 3 ? '冻结'
                                        : ele.status == 4 ? '停用'
                                        : ele.status == 10 ? '正常' : '未知状态'
                    })
                    state.total = result.data.total
                }
                state.loading = false
            }  catch(e) {
                state.loading = false
                console.error(e)
            }
        }
         // 确认框确认操作结果
        function confirmResult(msg: string) {
             loadData()
             showMessage(msg)
             state.showDialog = false
             state.checkedKeys = []
        }
        // 获取用户详情
        async function getDetail(id: number) {
            try {
                const detail = await userDetail(id)
                if (isValid(detail)) {
                    const _roles = detail.data.roleIds
                    state.myRoles.forEach((ele) => {
                        if(_roles.indexOf(ele.roleId) > -1) {
                            ele.isSelected = true
                        } else {
                            ele.isSelected = false
                        }
                    })
                } else {
                    state.showDialog = false
                }
            } catch(e) {
                console.error(e)
            }
        }
        // 获取roleId
        function getRoleIds() {
            const roles: any = []
            state.myRoles.forEach((ele) => {
                if(ele.isSelected) {
                    roles.push(ele.roleId)
                }
            })
            return roles.join(',')
        }
        // 编辑用户
        function edit(ele: any) {
            state.editRoleId = ele.userId
            state.title = '编辑用户'
            console.log(ele)
            state.formItems[1].hidden = true
            getDetail(ele.userId)
            state.formItems.forEach((e: any) => {
                if (ele[e.name] != void 0) {
                    e.value = ele[e.name]
                }
            })
            state.showDialog = true
        }
        // 添加用户
        function add() {
            state.editRoleId = ''
            state.title = '添加新用户'
            state.dialogType = 'edit'
            state.formItems[1].hidden = false
            state.myRoles.forEach(ele => ele.isSelected = false)
            state.formItems.forEach(ele => ele.value = '')
            state.passwordForm.forEach(ele => ele.value = '')
            state.showDialog = true
        }
        // 添加新用户
        async function addNew() {
            try {
                if (state.dialogType == 'resetPassword') {
                    await resetPassword()
                    return
                }
                const forms = state.forms
                const params = {
                    name: forms.trueName,
                    userName: forms.loginName,
                    phone: forms.phone,
                    email: forms.email,
                    password: state.editRoleId ? undefined : decodePassword(forms.passWord),
                    roleIds: getRoleIds(),
                    userId: '',
                    status: forms.status,
                }
                if(state.editRoleId != '') {
                    params.userId = state.editRoleId
                    const result = await editUser(params)
                    if(result.code == 0) {
                       confirmResult('编辑用户成功')
                    }
                } else {
                    const result = await addUser(params)
                    if(result.code == 0) {
                        confirmResult('添加用户成功')
                    }
                }
            } catch(e) {
                console.error(e)
            }
        }
        // 删除角色
        async function deleteRoles(data: any) {
            try {
                const isDelete = await confirmDialog('删除', '是否确认删除角色')
                if(isDelete) {
                    const result = await deleteRole(data.roleId)
                    if(result.code == 0){
                        showMessage('删除成功')
                        loadData()
                    }
                }
            } catch(e) {
                console.error(e)
            }
        }
        loadData()
        // 操作事件
        function handleEvent(eventName: string, value: any) {
            state.dialogType = eventName
            state.passwordForm.forEach(ele => ele.value = '')
            state.userInfo = value
            switch(eventName) {
                case 'edit':
                    edit(value)
                    break
                case 'resetPassword':
                    break
            }
            state.showDialog = true
        }
        function pageChange(data: any) {
            state.currentPage = data
            loadData()
        }
        return {
            roles,
            ...toRefs(state),
            edit,
            addNew,
            deleteRoles,
            add,
            pageChange,
            trueDialogForm,
            handleEvent
        }
    },
}
